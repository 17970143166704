/* import fonts */
@import url('./Fonts.css');

/* import tailwind css */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer tailwind {
  @import 'tailwindcss/base';
}


* {
  margin: 0;
  padding: 0;
  text-align: center;
}
html, body {
  background-color: #FFFFFF;
  height: 100%;
}
p {
  font-family: "Inter";
  color: #101828;
}
iframe {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}